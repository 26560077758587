
import Vue, { PropType } from "vue";

import http from "@/http";

import {
  OwnerResponse,
  Owner,
  Thread,
  Building,
  User,
  Snackbar
} from "@/interfaces";

import MyOverview from "@/components/owner/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    disabled(): boolean {
      return (
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        ) === false
      );
    }
  },
  methods: {
    async submit() {
      const url = `/owners/${this.owner.id}/users`;

      await http
        .post<User>(url, {
          email: this.email
        })
        .catch(error => {
          const { data, status } = error.response;
          if (status === 422) {
            const snackbar: Snackbar = {
              show: true,
              content: data.errors.email[0],
              color: "error"
            };

            this.$store.commit("setSnackbar", snackbar);
          }
        });

      this.$router.push(`/owner/${this.owner.id}`);
    }
  }
});
